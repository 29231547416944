window.firstPageHeight = 100;
window.headerHeight = 50;

function testSupportsSmoothScroll() {
    var supports = false;
    try {
        var div = document.createElement('div');
        div.scrollTo({
            top: 0,
            get behavior() {
                supports = true;
                return 'smooth';
            }
        });
    } catch (err) {}
    return supports;
}


function showHideHeader(scrollTopPosition) {
    // console.log("STP:" + scrollTopPosition + ";HH:" + window.headerHeight)

    if (scrollTopPosition > window.headerHeight) {
        document.getElementsByTagName("header")[0].classList.add('small');
    } else {
        document.getElementsByTagName("header")[0].classList.remove('small');
    }
}

function showHideGotoTop(scrollTopPosition) {
    if (scrollTopPosition > window.firstPageHeight) {
        document.getElementById("goto-top").classList.add('show');
    } else {
        document.getElementById("goto-top").classList.remove('show');
    }
}

function removePseudoClass(selector) {
    var aEl = document.querySelector(selector),  // Active Element
    nEl = aEl && aEl.nextSibling,              // The node following it
    pEl = aEl && aEl.parentNode;               // The parent node

    if (aEl && pEl) {
        pEl.removeChild(aEl);
        pEl.insertBefore(aEl, nEl);
    } 
}

function onScrollFunction() {
    var st = document.body.scrollTop || document.documentElement.scrollTop;

    showHideHeader(st);
    showHideGotoTop(st);

    if(st>window.firstPageHeight) {
        removePseudoClass('div:hover');
        removePseudoClass('div:active');
    }
}

function onResizeFunction() {}

function animateIntroElement(index,currentElement, remainingElements) {
    currentElement.keyframes = [{
        opacity: 0,
    }, {
        opacity: 1,
    }];

    var duration=1000/(1+index*index/2);
    // console.log("d="+duration);

    currentElement.animProps = {
        duration: duration,
        easing: "ease",
        iterations: 1
    };
    
    var player = currentElement.animate(currentElement.keyframes, currentElement.animProps);

    player.addEventListener('finish', function () {
        currentElement.classList.add('animation-finished');
        if (remainingElements.length > 0) {
            animateIntroElement(index+1,remainingElements.shift(), remainingElements);
        }
    });
}

function animateIntro() {
    var webAnimationsSupport = (window.Element.prototype.animate !== undefined);

    var companies = document.getElementsByClassName("gard");
    var gaspare=document.getElementById("gaspare");

    if (!webAnimationsSupport) {
        void 0;
        gaspare.classList.add('animation-finished');
        for (var i = 0; i < companies.length; i++) {
            companies.classList.add('animation-finished');
        }
        return;

    }
    var elements = Array.from(companies);
    animateIntroElement(0,gaspare, elements);
}

function onReady() {
    window.supportsSmoothScroll = testSupportsSmoothScroll();

    window.headerHeight = document.getElementsByTagName("header")[0].getBoundingClientRect().height;

    onScrollFunction();
    onResizeFunction();

    window.onscroll = function () {
        onScrollFunction();
    };

    window.onresize = function () {
        onResizeFunction();
    };

    animateIntro();
}